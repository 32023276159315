.shadow-button {
  box-shadow: 0px 2px 0px 0px #00000004;
}

label {
  margin-bottom: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #000000d9;
  font-weight: normal;
}
select {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex: 0.915;
}
