.app-container {
  display: flex;
  height: 100vh;
  background-color: white;
  flex: 3;
}
.app {
  background-color: white;
  margin-top: 20px;
  padding: 16px;
  border-radius: 15px;
}
.bg-\[\#00000026\] {
  background-color: #f6f6f6;
}
.sidebar {
  background: white;
  padding: 10px;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: #ccc;
  height: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  margin-right: 16px;
}

.employee {
  padding: 15px;
  border: none;
  margin-bottom: 5px;
  background: white;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.employeeSelected {
  width: 100%;
  padding: 15px;
  border: none;
  margin-bottom: 5px;
  background: #fff1f0;
  border-radius: 8px;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.initials {
  font-weight: bold;
}

.calendar-container {
  flex: 2;
  padding: 10px;
  background-color: white;
  border: none;
}

.rbc-calendar {
  height: 100vh;
  border-left: 1px solid #ccc;
}

.rbc-event {
  border-radius: 4px;
  border: none !important;
  border-width: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(49, 116, 173, 0.6);
  overflow: hidden;
  overflow-wrap: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px;
  font-size: 0.8rem;
}
.rbc-header {
  background-color: #fafafa;
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #333;
  border: none;
  padding: 5px 0;
}

.rbc-header + .rbc-header {
  border-left: 0;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-allday-cell {
  display: none !important;
}

.rbc-row.rbc-allday-cell .rbc-label {
  display: none !important;
}

.rbc-time-slot .rbc-label {
  color: #71717a !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
}

.rbc-time-header-content {
  min-height: 0 !important;
}

.rbc-show-more {
  display: none !important;
}
.rbc-time-view {
  border: none;
}
.rbc-time-content {
  border: none;
}
.rbc-time-content .rbc-time-slot {
  height: auto;
  min-height: 2em;
  background-color: white !important;
}

.rbc-event-content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.rbc-row-content .rbc-row {
  min-height: 3em;
}

.rbc-event-title {
  font-size: 0.7rem;
  margin-bottom: 4px;
}

.rbc-event-time {
  font-size: 0.8rem;
}
.custom-event-container {
  border-left: 5px solid #7b4bff;
  background-color: #f0f5ff;
  padding: 10px;
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
.custom-event-customer-name {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.custom-event-service-name {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.custom-event-time {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.custom-event {
  display: flex;
  flex-direction: column;
  background-color: rgba(49, 116, 173, 0.6);
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
}
.employeeAvatarContainer {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: orange;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 8px;
  padding: 5px;
}
.avatarEmployeeName {
  font-size: 16;
  font-weight: 500;
  color: white;
  justify-self: center;
  align-self: center;
  text-align: center;
}
.nameTextAvatar {
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
}
.specialty {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #8c8c8c;
  text-align: left;
}
.search-bar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row;
  margin-bottom: 10px;
}

.search-bar input[type="text"] {
  width: 250px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-bar button {
  padding: 10px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%); /* Center it vertically */
}
.viewAllBt {
  color: #e22526;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.search-bar-form {
  position: relative;
  display: inline-block;
  flex-direction: row;
}
.search-bar-form-content {
  flex-direction: row;
  display: flex;
}
/*Header styles*/
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background: #fff;
}

.nav-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  float: left;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #ccc;
}

.nav-list li {
  list-style: none;
  float: left;
  border-bottom: 2px solid #ccc;
  margin-right: 5px;
  margin-left: 0px;
  margin-bottom: -2px;
}

.nav-list li a {
  text-decoration: none;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  color: black;
  margin-bottom: 1px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.nav-item.active {
  border-bottom: 2px solid #ea6559;
}

.nav-item.active a {
  border: 0;
  color: red;
  padding: 5px;
}

.nav-item.active::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 2px;
  /* background: red; */
}

.nav-item a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.actions-container-booking {
  display: flex;
  align-items: center;
}

.dropbtn {
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
  justify-content: space-between;
}
.container-add-booking {
  flex-direction: row;
  height: 30px;
}
.add-booking {
  height: 40px;
  width: 160px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #de0607;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-header-container {
  flex-direction: row;
  display: flex;
  align-self: center;
  justify-content: center;
}
.custom-hd-label {
  text-align: center;
  align-self: center;
  color: #262626 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

/*Calendar layout*/

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
}

h1 {
  margin: 0;
  font-size: 16px;
}

.date-picker-calendar {
  position: relative;
  border: 1px solid #ccc;
  padding: 3px;
  border-radius: 5px;
  color: #262626 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
.plus-icon {
  padding-right: 10px;
  height: max-content;
}
.date-picker input[type="date"] {
  padding: 4px;
  border: 2px solid #ccc;
  border-radius: 4px;
  color: gray !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: opacity(50%);
}
.date-picker button {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: gray !important;
}

.dropdown {
  background-color: white;
  border-color: #d9d9d9 !important;
  flex-direction: row;
  display: flex;
}
.dropbtn {
  height: 40px;
  background-color: white;
  border-color: #d9d9d9 !important;
  width: 300px;
  text-align: start;
  align-items: center;
  flex-direction: row;
  display: flex;
  color: #8c8c8c !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: opacity(50%);
}
.dropdown-icon {
  height: 100 !important;
  width: 100 !important;
  justify-content: space-between;
  align-self: center;
  display: flex;
}
