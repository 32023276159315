.modal-overlay-booking-small {
  position: fixed;
  bottom: 1%;
  right: 16%;
  z-index: 100;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: white;
}

.modal-booking-short {
  background: white;
  border-radius: 8px;
  width: 500px;
  min-height: 550px;
}
.modal-header-booking-short {
  background-color: #fafafa;
  flex-direction: row;
  display: flex;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 10px;
  justify-content: space-between;
}

.modal-header-title-booking-short {
  font-weight: bold;
  font-size: 16px;
  color: black;
  line-height: 22px;
  width: 100%;
}

.close-button {
}

.modal-actions-booking-short {
  margin-left: 50px;
  margin-right: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  position: absolute;
  bottom: 1px;
}

.no-show {
  border-width: 1px;
  width: 120px;
  margin-right: 2px;
  border-radius: 10px;
  border-color: #ffa39e;
}

.cancel {
  border-width: 1px;
  width: 120px;
  border-radius: 10px;
  border-color: #ffa39e;
  margin-right: 2px;
}

.checkout {
  border-width: 1px;
  width: 120px;
  border-radius: 10px;
  background-color: #ea6559;
  color: white;
  border-color: #ffa39e;
  margin-right: 2px;
}

.header-details-tab {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 10px;
  background: #fff;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
}

.nav-list-details {
  list-style: none;
  display: flex;
  float: left;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #ccc;
  width: 100%;
  justify-content: space-around;
}

.nav-list-details li {
  width: 100%;
  list-style: none;
  float: left;
  border-bottom: 2px solid #ccc;
  margin-right: 5px;
  margin-left: 0px;
  margin-bottom: -2px;
}

.nav-list-details li a {
  text-decoration: none;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  color: black;
  margin-bottom: 1px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.nav-item-details.active {
  border-bottom: 2px solid #ea6559;
}

.nav-item-details.active a {
  border: 0;
  color: red;
  padding: 5px;
}

.nav-item-details.active::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 2px;
  background: red;
}

.nav-item-details a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.actions {
  display: flex;
  align-items: center;
}

.input-title {
  font-weight: bold;
  font-size: 12px;
  color: #8c8c8c;
  line-height: 20px;
  text-align: left;
  align-self: flex-start;
}
.input-text {
  font-weight: 400;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  text-align: left;
}
.payment-status-text {
  font-weight: 400;
  font-size: 12px;
  color: #fac714;
  line-height: 20px;
  text-align: left;
}
.input-container-booking-short {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0000000f;
  /* padding: 6px; */
  padding-top: 4px;
  padding-bottom: 4px;
  align-items: center;
  /* margin: 10px 20px; */
}

.information-boxes-booking-short {
  flex-direction: row;
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

.information-box-booking-short {
  border-width: 0.7px;
  border-color: rgba(175, 175, 175, 0.2f);
  border-radius: 5px;
  width: 90px;
  justify-content: center;
  align-items: center;
}

.information-box-title {
  font-weight: 400;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  text-align: center;
}

.information-box-value {
  font-weight: bold;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  text-align: center;
}

.detail-booking-short {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0000000f;
  padding-top: 4px;
  padding-bottom: 4px;
  align-items: center;
  width: 100%;
}

.label-booking-short {
  font-weight: bold;
  font-size: 12px;
  color: #00000073;
  line-height: 20px;
  text-align: left;
  width: 100%;
  max-width: 40%;
}

.value-booking-short {
  font-weight: 400;
  font-size: 14px;
  color: #000000d9;
  line-height: 22px;
  text-align: left;
  width: 60%;
}

.send-message-container {
  margin-left: 50px;
  margin-right: 50px;
}

.message-input-header-booking-short {
  margin-bottom: 8px;
  color: #555;
  font-size: 12px;
}

.message-input-booking-short {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  resize: none; /* Prevents the user from resizing the textarea */
}

.message-input-footer-booking-short {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.send-button-booking-short {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
}

.char-counter-booking-short {
  color: #555;
}
