.modal-add-user {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-user-info-modal {
    background: #fff;
    border-radius: 8px;
    width: 500px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-user-info-modal h2 {
    margin-top: 0;
  }
  
  .add-user-info-modal form {
    display: flex;
    flex-direction: column;
  }
  
  .add-user-info-modal label {
    margin-bottom: 10px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #8c8c8c;
    font-weight: bold;
  }
  
  .add-user-info-modal input,
  .add-user-info-modal select {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 250px;
  }
  
  .add-user-info-modal .buttons {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .add-user-info-modal button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-user-info-modal-header{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding-left: 7px;
    padding-right: 7px;
  }
  
  .add-user-input-container {
    margin-left: 50px;
    margin-right: 50px;
    justify-content: space-around;
    align-items: center;
  }
  
  .cancel-add-user {
    border-width: 1px !important;
    width: 120px;
    border-radius: 10px;
    border-color: #FFA39E !important;
    margin-right: 4px;
    background-color: white;
    cursor: pointer;
  }
  
  .submit-add-user {
    border-width: 1px;
    width: 120px;
    border-radius: 10px;
    background-color: #DE0607;
    color: white;
    border-color: #FFA39E;
    cursor: pointer;
  }