.modal-add-vendor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-vendor-info-modal {
  background: #fff;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.add-vendor-info-modal h2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.add-vendor-info-modal form {
  display: flex;
  flex-direction: column;
}

.add-vendor-info-modal label {
  margin-bottom: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #8c8c8c;
  font-weight: bold;
}

.add-vendor-info-modal input,
.add-vendor-info-modal select {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 320px;
}

.add-vendor-info-modal .buttons {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-vendor-info-modal button {
  padding: 7px 15px;
  border-radius: 12px;
  cursor: pointer;
  height: 40px;
  width: 160px;
  align-items: center;
}

.add-vendor-info-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding-left: 24px;
}

.add-vendor-input-container {
  margin-left: 24px;
  margin-right: 24px;
  justify-content: space-around;
  align-items: center;
}

.cancel-add-vendor {
  border-width: 1px !important;
  width: 120px;
  border-radius: 10px;
  border-color: #ffa39e !important;
  margin-right: 4px;
  background-color: white;
  cursor: pointer;
}

.submit-add-vendor {
  border-width: 1px;
  width: 120px;
  border-radius: 10px;
  background-color: #de0607;
  color: white;
  border-color: #ffa39e;
  cursor: pointer;
}
.switch-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8;
  padding-bottom: 8;
}

.label-text {
  width: 216px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  align-self: flex-start;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 16px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #de0607;
}

input:checked + .slider:before {
  transform: translateX(12px);
}
