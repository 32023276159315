.edit-vendor-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-vendor-info-modal {
    background: #fff;
    border-radius: 8px;
    width: 600px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .edit-vendor-info-modal h2 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
  }
  
  .edit-vendor-info-modal form {
    display: flex;
    flex-direction: column;
  }
  
  .edit-vendor-info-modal label {
    margin-bottom: 10px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #8c8c8c;
    font-weight: bold;
  }
  
  .edit-vendor-info-modal input,
  .edit-vendor-info-modal select {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 250px;
  }
  
  .edit-vendor-info-modal .buttons {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .edit-vendor-info-modal button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
  .edit-vendor-info-modal-header{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding-left: 7px;
    padding-right: 7px;
  }
  
  .edit-vendor-input-container{
    margin-left: 24px;
    margin-right: 24px;
    justify-content: space-around;
    align-items: center;
  }
  
  .cancel-edit-vendor{
    border-width: 1px!important;
    width: 120px;
    border-radius: 10px;
    border-color: #FFA39E!important;
    margin-right: 4px;
    background-color: white;
  }
  
  .submit-edit-vendor{
    border-width: 1px;
    width: 120px;
    border-radius: 10px;
    background-color: #DE0607;
    color: white;
    border-color: #FFA39E;
  }

  .switch-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 8;
    padding-bottom: 8;
  }

  .label-text {
    width: 216px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
    align-self: flex-start;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 16px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #DE0607;
  }
  
  input:checked + .slider:before {
    transform: translateX(12px);
  }
