.banner-upload-container {
  width: 356px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-right: 32px;
}

.banner-upload-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.banner-upload-toggle {
  background-color: transparent;
  color: white;
  border-radius: 20px;
  padding: 2px 12px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}

.banner-upload-body {
  display: flex;
  flex-direction: column;
}

.banner-upload-area {
  border: dashed 2px #ccc;
  border-radius: 8px;
  text-align: center;
  padding: 30px 0;
  margin-bottom: 10px;
}

.banner-upload-dropdown select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.banner-upload-requirements p,
.banner-upload-requirements ul {
  font-size: 12px;
  color: #666;
}

.banner-upload-requirements ul {
  padding-left: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px; /* Increased width for larger switch */
  height: 25px; /* Increased height for larger switch */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Default background */
  transition: 0.4s;
  border-radius: 34px;
}

.slider.active {
  background-color: #ff3e00; /* Active color */
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px; /* Adjust for larger switch */
  width: 22px; /* Adjust for larger switch */
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3; /* Checked color */
}

input:checked + .slider:before {
  transform: translateX(22px); /* Adjust for larger switch */
}

.slider.round {
  border-radius: 34px;
}
.slider.round.on {
  border-radius: 34px;
  text-align: left;
  font-size: small;
}
.slider.round.off {
  border-radius: 34px;
  text-align: right;
  font-size: small;
}
.slider.round:before {
  border-radius: 50%;
}

.slider.active:before {
  transform: translateX(48px); /* Adjust translation for active state */
}

/* Style for the ON/OFF text */
.slider:before {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; /* Larger text size */
  font-weight: bold; /* Bold text */
}

.cancel-edit-vendor {
  width: 170px !important;
}

.submit-edit-vendor {
  width: 170px !important;
}
