.modal-layout {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  border-bottom: 2px solid transparent;
}

.tabs button.active {
  border-bottom-color: red;
}

.tab-content {
  margin-bottom: 20px;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: absolute;
  bottom: 50px;
  width: 100%;
}
.services-actions {
  display: flex;
  justify-content: end;
  align-items: end;
  align-self: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100%;
}
.actions button {
  padding: 5px 40px;
  cursor: pointer;
  outline: none;
  background-color: white;
  border: 1px solid #ffa39e;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: max-content;
  color: #262626;
}

.actions button.checkout {
  background-color: red;
  color: white;
  border-color: red;
  text-align: center;
  padding: 5px 40px;
}

.bottom-buttons {
  height: 40px;
  margin-right: 10px;
  border-width: 1px;
}

.details-container {
  width: 100%;
}
.input-container {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  border-bottom-width: 1.2px;
  padding: 9px;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: 0px;
  margin-left: 0px;
}

.input-title {
  width: 50%;
  font-weight: bold;
  font-size: 12px;
  color: #00000073;
  line-height: 20px;
  text-align: left;
  align-self: flex-start;
}
.input-text {
  width: 50%;
  font-weight: 400;
  font-size: 14px;
  color: #000000d9;
  line-height: 22px;
  text-align: left;
}

.information-boxes {
  flex-direction: row;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

.information-box {
  border-width: 0.7px;
  border-color: rgba(175, 175, 175, 0.2f);
  border-radius: 5px;
  width: 90px;
  justify-content: center;
  align-items: center;
}

.payment-form {
  width: 300px;
  margin: auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Arial", sans-serif;
}

.payment-form h2,
.payment-form h3 {
  margin-bottom: 10px;
}

.service-details,
.payment-method,
.form-actions {
  margin-bottom: 20px;
}
.total-amount {
  margin-bottom: 20px;
  flex-direction: row;
  display: flex;
}
.service-details p,
.total-amount p {
  margin: 0;
  line-height: 1.5;
}
.payment-method {
  width: 100%;
}
.payment-cards-container {
  width: 100%;
  justify-content: space-around;
  display: flex;
  margin-top: 20px;
}
.payment-method button {
  margin-right: 5px;
  padding: 30px 50px;
  border-radius: 20px;
  cursor: pointer;
}

.method.card.selected {
  background-color: white;
  color: black;
  border-color: red;
  border-width: 1px;
  font-weight: bold;
  font-size: 16;
  border-radius: 10px;
}

.method.cash.selected {
  background-color: white;
  color: black;
  border-color: red;
  border-width: 1px;
  font-weight: bold;
  font-size: 16;
  border-radius: 10px;
}

.method.online.selected {
  background-color: white;
  color: black;
  border-color: red;
  border-width: 1px;
  font-weight: bold;
  font-size: 16;
  border-radius: 10px;
}

.method.card {
  background-color: white;
  color: black;
  border-color: #d9d9d9;
  border-width: 1px;
  font-weight: bold;
  font-size: 16;
  border-radius: 10px;
}

.method.cash {
  background-color: white;
  color: black;
  border-color: #d9d9d9;
  border-width: 1px;
  font-weight: bold;
  font-size: 16;
  border-radius: 10px;
}

.method.online {
  background-color: white;
  color: black;
  border-color: #d9d9d9;
  border-width: 1px;
  font-weight: bold;
  font-size: 16;
  border-radius: 10px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions .form-actions {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-services {
  padding: 10px 60px;
  border-radius: 10px;
  border-width: 1px;
  cursor: pointer;
  background-color: #ffffff;
  border-color: #dc3545;
  margin-right: 10px;
  text-align: center;
  justify-content: center;
  display: flex;
}

.confirm {
  padding: 10px 60px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #dc3545;
  color: white;
  margin-right: 10px;
  text-align: center;
  display: flex;
}

.service-details-container {
  max-width: 600px;
  margin: auto;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Arial", sans-serif;
}

.detail {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  flex-direction: row;
  display: flex;
}

.label {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  color: #8c8c8c;
}

.value {
  display: inline-block;
  word-break: break-word; /* Ensures long text will wrap and not overflow */
}

.message-input-container {
  max-width: 500px;
  margin: auto;
  font-family: "Arial", sans-serif;
}

.message-input-header {
  margin-bottom: 8px;
  color: #555;
}

.message-input {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  resize: none; /* Prevents the user from resizing the textarea */
}

.message-input-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.send-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.char-counter {
  color: #555;
}
