.status-header {
  width: 140px;
}

.button-container {
  position: relative;
  display: inline-block;
}

.modal {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(10px);
}
