.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-booking {
  z-index: 1060;
  background: white;
  position: fixed;
  border-radius: 3px;
  max-width: 41%; /* Half screen size */
  width: 41%;
  height: 100%;
  right: 0;
  bottom: 0;
  top: 0;
}

.modal-header {
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
}

.modal-close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
