@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 64px; /* 16px in your original code, but larger spinners are more visible */
  height: 64px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top-color: #3498db; /* Blue color for the top of the spinner */
  animation: spin 1s linear infinite;
}
