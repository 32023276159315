.booking-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 160px;
}

.booking-card h2 {
  margin: 0;
  color: #000000d9;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap; /* Prevents wrapping */
  padding: 10px;
  padding-top: 0;
  padding-left: 0;
  text-align: left;
  font-weight: 500;
}
.list-view-phone-container {
  flex-direction: row;
  display: flex;
  padding: 0 0;
  margin: 0 0;
  width: fit-content !important;
  margin-left: 0;
  flex: 1;
}
.booking-card p {
  margin: 4px 0;
  color: #262626;
  white-space: nowrap; /* Prevents wrapping */
  width: 140px;
  font-size: 14px;
  align-self: center;
  justify-content: center;
  margin-left: 2px;
}

.booking-card button {
  padding: 7px 45px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 0.8rem;
}

.cancel-button {
  border-width: 1px;
  border-radius: 50px;
  border-color: #ffa39e;
  color: black;
  margin-right: 2px;
  display: flex;
  text-align: center;
  white-space: nowrap; /* Prevents wrapping */
  width: 140px;
  align-self: center;
  justify-content: center;
}

.checkout-button {
  background-color: #de0607;
  color: white;
  margin-left: 2px;
  text-align: center;
  width: 140px;
  align-self: center;
  justify-content: center;
}

.list-view-button-containers {
  flex-direction: row;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  flex: 1;
}

.list-view-details-container {
  flex-direction: row;
  display: flex;
  flex: 1.5;
}

.detail-texts {
  flex-direction: row;
  display: flex;
  justify-content: left;
  padding-left: 20px;
  align-items: center;
}

.list-view-phone-number {
  color: #8c8c8c;
  white-space: nowrap; /* Prevents wrapping */
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  width: fit-content;
  font-weight: 400;
}

.phone-number {
  color: #000000d9;
}

.phone-number-title {
  color: #00000073;
}

.name-container {
  flex: 0.7;
  border-right-width: 1.2px;
  border-right-color: #f0f0f0;
}
