.triangle-right {
  width: 0;
  height: 0;
  border-top: 8.5px solid transparent;
  border-bottom: 8.5px solid transparent;
  border-left: 8px solid #FFFFFF;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.15));
  margin-top: 8.5px;
}

.w-236 {
  width: 236px;
}
  
.shadow-around {
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15);
}