.modal-overlay-booking-half {
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
}

.modal-booking-half {
  background: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  position: relative;
}

.modal-header-booking-half {
  background-color: #fafafa;
  flex-direction: row;
  display: flex;
  border-top-right-radius: 8px;
  padding: 10px;
  justify-content: space-between;
}

.modal-header-title-booking-half {
  font-weight: bold;
  font-size: 16px;
  color: black;
  line-height: 22px;
  width: 100%;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-actions-booking-half {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.no-show,
.cancel,
.checkout {
  border-width: 1px;
  width: 120px;
  border-radius: 10px;
  margin-right: 10px;
}

.no-show {
  border-color: #ffa39e;
}

.cancel {
  border-color: #ffa39e;
}

.checkout {
  background-color: #ea6559;
  color: white;
  border-color: #ffa39e;
}

.header-details-tab {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 10px;
  background: #fff;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
}

.nav-list-details {
  list-style: none;
  display: flex;
  border-bottom: 2px solid #ccc;
  width: 100%;
  justify-content: space-around;
}

.nav-list-details li {
  width: 100%;
  list-style: none;
  float: left;
  border-bottom: 2px solid #ccc;
  margin-right: 5px;
  margin-bottom: -2px;
}

.nav-list-details li a {
  text-decoration: none;
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
  color: black;
  margin-bottom: 1px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.nav-item-details.active {
  border-bottom: 2px solid #ea6559;
  width: 86px;
  text-align: center;
}

.nav-item-details.active a {
  color: red;
  padding: 5px;
}

.nav-item-details.active::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 2px;
  background: red;
}

.nav-item-details a {
  color: #333;
  font-weight: bold;
  width: 100px;
  justify-content: center;
  text-align: center;
}

.input-title {
  font-weight: bold;
  font-size: 12px;
  color: #00000073;
  line-height: 20px;
  text-align: left;
  width: 100%;
  max-width: 40%;
}

.input-text {
  font-weight: 400;
  font-size: 14px;
  color: #000000d9;
  line-height: 22px;
  text-align: left;
  max-width: 60%;
  width: 100%;
}

.payment-status-text {
  font-weight: 400;
  font-size: 12px;
  color: #fac714;
  line-height: 20px;
  text-align: left;
}

.input-container-booking-half {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0000000f;
  /* padding: 6px; */
  padding-top: 4px;
  padding-bottom: 4px;
  align-items: center;
  /* margin: 10px 20px; */
}

.information-boxes-booking-half {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.information-box-booking-half {
  border-width: 0.7px;
  border-color: rgba(175, 175, 175, 0.2);
  border-radius: 5px;
  width: 90px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.information-box-title {
  font-weight: 400;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  text-align: center;
}

.information-box-value {
  font-weight: bold;
  font-size: 12px;
  color: #262626;
  line-height: 20px;
  text-align: center;
}

.detail-booking-half {
  margin-bottom: 15px;
  padding-bottom: 10px;
  margin: 0 20px;
}

.label-booking-half {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
  color: #8c8c8c;
  font-size: 14px;
}

.value-booking-half {
  display: inline-block;
  word-break: break-word;
  font-size: 14px;
}

.send-message-container {
  margin: 20px;
}

.message-input-header-booking-half {
  margin-bottom: 8px;
  color: #555;
  font-size: 12px;
}

.message-input-booking-half {
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
  resize: none;
}

.message-input-footer-booking-half {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.send-button-booking-half {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
}

.char-counter-booking-half {
  color: #555;
}
